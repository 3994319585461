<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_orange_logo_horizontal.png"
          style="max-height: 60px;"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen text-center" style="height: 80vh;">

      <div class="m-auto pb-10">

        <div class="mb-12 relative">
          <router-link
            :to="{name: 'registration.v2.welcome'}"
            class="absolute left-0"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left.svg"
              class="inline-block"
            > Back
          </router-link>

          <span>Approximately 5 min. to complete</span>
        </div>

        <div class="mt-9">
          <h3 class="text text-center text-neutral mb-9">
            First things first
          </h3>
        </div>

        <form
          class="mt-10 mb-20"
          @submit.prevent="register"
        >

          <h3 class="text text-center text-black mb-10">
            What's your full name?
          </h3>

          <div
            v-if="errorMessage"
            class="flex items-center text-dark text-sm mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-3">
            <input
              v-model="firstName"
              type="text"
              class="form-control"
              placeholder="First Name"
              required
              style='margin-right: 20px;'
            >

            <input
              v-model="middleName"
              type="text"
              class="form-control"
              placeholder="Middle Name"
              required
              style='margin-right: 20px;'
            >

            <input
              v-model="lastName"
              type="text"
              class="form-control"
              placeholder="Last Name"
              required
            >
          </div>

          <a href="#" class="text-primary font-bold">Why do you need my full name?</a>
        </form>


        <form
          class="mt-10 mb-10"
          @submit.prevent="register"
        >

          <h3 class="text text-center text-black mb-10">
            What should we call you?
          </h3>

          <div class="mb-3">
            <input
              v-model="preferredName"
              type="text"
              class="form-control w-4/6"
              placeholder="Preferred Name"
              required
            >
          </div>
        </form>

        <div class="mt-10 pt-10 text-center">
          <!-- <router-link
            :to="{name: 'registration.v2.signup.email'}"
            class="btn btn-primary-outline inline-block w-2/5"
          >
            Onwards
          </router-link> -->
          <button
            @click.prevent="submit()"
            class="btn btn-primary-outline inline-block w-2/5"
          >
            Onwards
          </button>
        </div>

        <div class="mt-10 text-center">
          <span class="dot"></span>
          <span class="dot active"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SignupName',
  data() {
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      preferredName: '',
      errorMessage: null
    };
  },
  watch: {
    firstName: function (value) {
      console.log(value)
    }
  },
  methods: {
    submit() {
      if (!this.firstName || this.firstName === "") {
        this.errorMessage = 'Please fill in the first name field and try again.'
        return false;
      }

      localStorage.setItem('firstname', this.firstName);
      localStorage.setItem('lastname', this.middleName);
      localStorage.setItem('middlename', this.lastName);
      localStorage.setItem('preferredname', this.preferredName);

      this.$router.push('/registration/v2/signup/email');
    }
  },
  mounted() {
    let firstName = localStorage.getItem('firstname');
    let lastName = localStorage.getItem('lastname');
    let middleName = localStorage.getItem('middlename');
    let preferredName = localStorage.getItem('preferredname');

    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }
    if (lastName && lastName !== '') {
      this.lastName = lastName;
    }
    if (middleName && middleName !== '') {
      this.middleName = middleName;
    }
    if (preferredName && preferredName !== '') {
      this.preferredName = preferredName;
    }
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
